<template>
  <div class="home-container" ref="container" v-loading="isLoading">
    <ul
      class="carousel-container"
      :style="{ marginTop }"
      @wheel="handleWheel"
      @transitionend="handleTransitionend"
    >
      <li v-for="item in data" :key="item.id">
        <CarouselItem :carousel="item" />
      </li>
    </ul>

    <div class="icon icon-up" @click="switchTo(index - 1)" v-show="index >= 1">
      <Icon type="arrowUp" />
    </div>
    <div
      class="icon icon-down"
      @click="switchTo(index + 1)"
      v-show="index < data.length - 1"
    >
      <Icon type="arrowDown" />
    </div>

    <ul class="indicator">
      <li
        :class="{ active: index === i }"
        v-for="(item, i) in data"
        :key="item.id"
        @click="switchTo(i)"
      ></li>
    </ul>
  </div>
</template>

<script>
import CarouselItem from "./CarouselItem.vue";
import Icon from "@/components/Icon";
import { mapState } from "vuex";
export default {
  components: {
    CarouselItem,
    Icon,
  },
  data() {
    return {
      index: 0,
      containerHeight: 0,
    };
  },
  created() {
    this.$store.dispatch("banner/fetchBanner");
  },
  computed: {
    ...mapState("banner", ["isLoading", "data"]),
    marginTop() {
      return -this.index * this.containerHeight + "px";
    },
  },
  mounted() {
    this.containerHeight = this.$refs.container.clientHeight;
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    switchTo(i) {
      this.index = i;
    },
    handleWheel(e) {
      if (this.switching) {
        return;
      }
      if (e.deltaY < -5 && this.index > 0) {
        this.switching = true;
        this.index--;
      } else if (e.deltaY > 5 && this.index < this.data.length - 1) {
        this.switching = true;
        this.index++;
      }
    },
    handleTransitionend() {
      this.switching = false;
    },
    handleResize() {
      this.containerHeight = this.$refs.container.clientHeight;
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "~@/styles/var.less";
@import "~@/styles/mixin.less";

.home-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    transition: 200ms;
  }
}
.indicator {
  .self-center;
  left: auto;
  right: 20px;
  transform: translateY(-50%);
  li {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: @words;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid #fff;
    box-sizing: border-box;
    &.active {
      background-color: #fff;
    }
  }
}
.carousel-container {
  width: 100%;
  height: 100%;
  li {
    width: 100%;
    height: 100%;
  }
}
.icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  font-size: 30px;
  color: @gray;
  @gap: 25px;
  &.icon-up {
    top: @gap;
    animation: jump-up 2s infinite;
  }
  &.icon-down {
    bottom: @gap;
    animation: jump-down 2s infinite;
  }
  @jump: 5px;

  @keyframes jump-up {
    0% {
      transform: translate(-50%, -@jump);
    }
    50% {
      transform: translate(-50%, @jump);
    }
    100% {
      transform: translate(-50%, -@jump);
    }
  }
  @keyframes jump-down {
    0% {
      transform: translate(-50%, @jump);
    }
    50% {
      transform: translate(-50%, -@jump);
    }
    100% {
      transform: translate(-50%, @jump);
    }
  }
}
</style>